.App {
  height: 100vh;
  background-color: rgb(20, 20, 20);
  text-align: center;
  overflow: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  text-align: center;
  background-color: #282c34;
  color: white;
  margin: 50px;
}

h1 {
  margin: 0;
  font-size: 5em;
}

#slogan {
  margin: 0;
  font-size: 2em;
}

button {
  background: black;
  color: white;
  border: 0;
  font-size: 1.5em;
  padding: 12px;
  cursor: pointer;
  margin-top: 30px;
}

.button-container{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  
}

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 30px 0 30px;
  color: white;
  position: relative;
}

.connect-wallet-button {
  color: white;
  background: -webkit-linear-gradient(left, #ff8867, #ff52ff);
  background-size: 200% 200%;
  animation: gradient-animation 4s ease infinite;
  max-width: 300px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.cta-button {
  height: 45px;
  border: 0;
  width: auto;
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  color: white;
}

.gradient-text {
  background: -webkit-linear-gradient(left, #60c657 30%, #35aee2 60%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.header {
  margin: 0;
  font-size: 50px;
  font-weight: bold;
}

.sub-text {
  font-size: 25px;
  color:white;
}

.wallet-button {
  color: white;
  background: -webkit-linear-gradient(#60c657 30%, #35aee2 60%);
  background-size: 200% 200%;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  border-width: 1px;
  padding: 13px;
  cursor: pointer;
  font-weight: 600;
  font-size: 1.2em;
}
.wallet-button:hover {
  background: -webkit-linear-gradient(left, #60c657, #35aee2);
}

.address {
  background-color:  #35aee2;
  color: white;
  padding: 5px;
  border-radius: 5px;
  border: none;
}